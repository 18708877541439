<template>
    <div class="goods-list-page">
        <GeekQooSearch @search="setKeyword"></GeekQooSearch>
        <div class="top-title">
            <p>{{ category.title }}</p>
        </div>
        <div class="category-tags">
            <a
                :class="style.activeIndex==-1?'active':''"
                @click="styleChange({id:null},-1)"
            >全部</a>
            <a
                v-for="(item,index) in style.list"
                :key="index"
                :class="style.activeIndex==index?'active':''"
                @click="styleChange(item,index)"
            >
                {{ item.title }}
            </a>
        </div>
        <div class="screening">
            <input v-model="minPrice" class="item-input" placeholder="最低价" type="text">
            <span class="item-word">一</span>
            <input v-model="maxPrice" class="item-input" placeholder="最高价" type="text">
            <van-button class="item-button" color="#F96719" round size="mini" @click="screenList()">筛选</van-button>
        </div>
        <!--<div class="select-screening">-->
        <!--    <van-button-->
        <!--        :color="selectScreenActive===0?'#F96719':'#999999'"-->
        <!--        class="item-button"-->
        <!--        round-->
        <!--        size="mini"-->
        <!--        @click="selectScreenList(0)"-->
        <!--    >-->
        <!--        0-1000-->
        <!--    </van-button>-->
        <!--    <van-button-->
        <!--        :color="selectScreenActive===1?'#F96719':'#999999'"-->
        <!--        class="item-button"-->
        <!--        round-->
        <!--        size="mini"-->
        <!--        @click="selectScreenList(1)"-->
        <!--    >-->
        <!--        1001-3000-->
        <!--    </van-button>-->
        <!--    <van-button-->
        <!--        :color="selectScreenActive===2?'#F96719':'#999999'"-->
        <!--        class="item-button"-->
        <!--        round-->
        <!--        size="mini"-->
        <!--        @click="selectScreenList(2)"-->
        <!--    >-->
        <!--        3001-5000-->
        <!--    </van-button>-->
        <!--    <van-button-->
        <!--        :color="selectScreenActive===3?'#F96719':'#999999'"-->
        <!--        class="item-button"-->
        <!--        round-->
        <!--        size="mini"-->
        <!--        @click="selectScreenList(3)"-->
        <!--    >-->
        <!--        5001-10000-->
        <!--    </van-button>-->
        <!--</div>-->
        <van-list
            v-model="goodsPagination.loading"
            :finished="goodsPagination.finished"
            class="goods-list"
            finished-text="没有更多了"
            @load="getGoodsList"
        >
            <div class="list-wrapper">
                <a v-for="(item,index) in goodsList" class="good-item" @click="toDetail(item)">
                    <div
                        :style="`background:url(${item.thumb_arr.length >0?item.thumb_arr[0]:item.thumb})`"
                        class="item-img"
                    ></div>
                    <div class="item-info">
                        <p v-if="item.bar_code" class="p1">组名：{{ item.bar_code ? item.bar_code : "暂无" }}</p>
                        <p class="p1">货号：{{ item.title }}</p>
                        <p class="p1">名称：{{ item.sub_title }}</p>
                        <p v-if="item.price" class="p2">￥{{ item.price }}</p>
                        <p v-else class="p2">暂无报价</p>
                    </div>
                </a>
            </div>
        </van-list>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入组件
import GeekQooSearch from "@/components/GeekQooSearch"
// 引入接口
import {GOODS_GROUP, GOODS_LIST} from "@/api/dataProvider.js"

export default {
    name: "GoodsList",
    mixins: [mixins_config],
    components: {GeekQooSearch},
    data() {
        return {
            keyword: "",
            // 分类
            categoryIdList: "",
            category: {
                title: "",
                activeId: ""
            },
            // 风格
            style: {
                list: [],
                activeId: "",
                activeIndex: -1
            },
            // 最低价
            minPrice: "",
            // 最高价
            maxPrice: "",
            // 筛选按钮选中
            selectScreenActive: -1,
            // 商品分类
            goodsList: [],
            // 分页相关
            goodsPagination: {
                current: 1,
                loading: false,
                finished: false,
            }
        };
    },
    activated() {
        if (this.$route.params.status != -1) {
            this.clearPagination()
            this.init()
            this.getGoodsList()
        }
    },
    created() {
        this.app.tabActive = 1
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            // 路由获取两级ID
            this.categoryIdList = this.$route.query.id.split("-").map(Number)
            this.category.activeId = this.categoryIdList[1]
            this.getGoodsGroup()
        },
        // 获取商品分类
        getGoodsGroup() {
            let that = this;
            // 获取两级分类标题
            GOODS_GROUP({}, function (res) {
                if (res.data.code == 0) {
                    res.data.data.group.map((item) => {
                        if (item.id == that.categoryIdList[0]) {
                            that.category.title = item.title
                            if (item.list.length > 0) {
                                item.list.map((citem) => {
                                    if (citem.id == that.categoryIdList[1]) {
                                        that.category.title = that.category.title + " - " + citem.title
                                    }
                                })
                            }
                        }
                    })
                }
            })
            // 获取风格列表
            GOODS_GROUP({
                id: this.$route.query.id
            }, function (res) {
                if (res.data.code == 0) {
                    that.style.list = res.data.data.group
                }
            })
        },
        // 切换风格
        styleChange(item, index) {
            this.style.activeIndex = index
            this.style.activeId = item.id
            this.clearPagination()
            this.getGoodsList()
        },
        // 筛选
        screenList() {
            this.clearPagination()
            this.getGoodsList()
        },
        // 选择筛选
        selectScreenList(type) {
            console.log(type)
            this.$loading.show()
            this.selectScreenActive = type
            if (type === 0) {
                this.minPrice = 0
                this.maxPrice = 1000
            } else if (type === 1) {
                this.minPrice = 1001
                this.maxPrice = 3000
            } else if (type === 2) {
                this.minPrice = 3001
                this.maxPrice = 5000
            } else if (type === 3) {
                this.minPrice = 5001
                this.maxPrice = 10000
            }
            this.clearPagination()
            this.getGoodsList()
        },
        // 初始化分页信息
        clearPagination() {
            this.goodsPagination = {
                current: 1,
                loading: false,
                finished: false,
            }
            this.goodsList = []
        },
        // 设置关键词
        setKeyword(val) {
            this.keyword = val
            this.clearPagination()
            this.getGoodsList()
        },
        // 获取商品列表
        getGoodsList() {
            let that = this
            GOODS_LIST(
                {
                    page: that.goodsPagination.current,
                    pageSize: 10,
                    // pid: 0,
                    keyword: that.keyword,
                    tags: that.category.activeId,
                    subTag: that.style.activeId,
                    minPrice: that.minPrice,
                    maxPrice: that.maxPrice
                },
                function (res) {
                    if (res.data.code == 0) {
                        that.goodsPagination.loading = false
                        // 列表有数据并且是第一页，那肯定是重复请求了，所以阻断掉
                        if (that.goodsList.length > 0 && res.data.data.current_page == 1) {
                            return false
                        }
                        that.goodsList = that.goodsList.concat(res.data.data.data)
                        if (res.data.data.last_page > that.goodsPagination.current) {
                            that.goodsPagination.current += 1;
                        } else {
                            that.goodsPagination.finished = true;
                        }
                    }
                }
            )
        },
        // 跳转商品详情
        toDetail(item) {
            this.$router.push({
                name: "GoodDetail",
                params: {id: item.id}
            })
        }
    }
}
</script>

<style lang="scss">
.goods-list-page {
    padding: 0 0 50px 0;

    .top-title {
        background: #F96719;

        p {
            line-height: 35px;
            text-align: center;
            font-size: 10px;
            color: #fff;
        }
    }

    .category-tags {
        padding: 10px 10px 0 10px;

        a {
            display: inline-block;
            min-width: calc(25% - 14px);
            margin: 7px;
            padding: 7px 0;
            border: 1px solid #eeeeee;
            text-align: center;
            font-size: 10px;
            color: #999999;
        }

        .active {
            border-color: #F96719;
            color: #F96719;
        }
    }

    .screening {
        margin: 7px 0 0 0;
        padding: 0 17px;
        text-align: center;

        .item-input {
            vertical-align: middle;
            width: 123px;
            line-height: 24px;
            background: #f8f8f8;
            border: 1px solid #f8f8f8;
            border-radius: 15px;
            text-align: center;
            font-size: 12px;
            color: #333;
        }

        .item-word {
            vertical-align: middle;
            line-height: 24px;
            margin: 0 10px;
            font-size: 12px;
            color: #333;
        }

        .item-button {
            padding: 0 15px;
            vertical-align: middle;
            margin-left: 10px;
        }
    }

    .select-screening {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 7px 0 0 0;
        padding: 0 17px;

        .item-button {
            flex: 0 0 calc(25% - 5px);
            margin: 2.5px;
        }
    }

    .goods-list {
        padding: 0 17px 0 17px;

        .list-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .good-item {
                display: block;
                width: 163px;
                margin-top: 14px;
                padding: 3px;
                border: 1px solid #EEEEEE;

                .item-img {
                    width: 100%;
                    height: 94px;
                    background-repeat: no-repeat !important;
                    background-size: contain !important;
                    background-position: center !important;
                }

                .item-info {
                    padding: 10px;

                    .p1 {
                        margin-bottom: 10px;
                        font-size: 10px;
                        color: #2D2D2D;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .p2 {
                        font-size: 10px;
                        font-weight: bold;
                        color: #F54A4A;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}
</style>
